<template>
  <div>
    <v-row class="mx-1">
      <v-col cols="12">
        <v-card class="px-2 py-4 rounded-lg" id="card-custom">
          <p class="headline-color px-2">Detail Kehadiran</p>
          <v-divider />
          <v-row>
            <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
              <v-autocomplete
                :items="units"
                v-model="unit"
                :loading="loadingUnit"
                placeholder="Unit Utama"
                outlined
                dense
                hide-details
                item-text="singkatan"
                item-value="unit_utama_id"
                clearable
                @change="changeUnit(1)"
                @click:clear="changeUnit(1)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
              <v-autocomplete
                :items="units2"
                v-model="unit2"
                :loading="loadingUnit"
                placeholder="Unit Kerja"
                outlined
                dense
                hide-details
                item-text="singkatan"
                item-value="unit_kerja_2_id"
                clearable
                @change="changeUnit(2)"
                @click:clear="changeUnit(2)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
              <v-autocomplete
                :items="units3"
                v-model="unit3"
                :loading="loadingUnit"
                placeholder="Unit Kerja"
                outlined
                dense
                hide-details
                item-text="singkatan"
                item-value="unit_kerja_3_id"
                clearable
                @change="changeUnit(3)"
                @click:clear="changeUnit(3)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
              <v-autocomplete
                :items="units4"
                v-model="unit4"
                :loading="loadingUnit"
                placeholder="Unit Kerja"
                outlined
                dense
                hide-details
                item-text="singkatan"
                item-value="unit_kerja_4_id"
                clearable
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="3" sm="3" xs="12">
              <v-menu
                v-model="menuStart"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateStart"
                    placeholder="Tanggal Mulai"
                    append-icon="event"
                    outlined
                    dense
                    hide-details
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dateStart"
                  locale="id"
                  no-title
                  scrollable
                  @input="menuStart = false"
                />
              </v-menu>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="3" sm="3" xs="12">
              <v-menu
                v-model="menuEnd"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateEnd"
                    placeholder="Tanggal Akhir"
                    append-icon="event"
                    outlined
                    dense
                    hide-details
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dateEnd"
                  locale="id"
                  no-title
                  scrollable
                  @input="menuEnd = false"
                />
              </v-menu>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="3" sm="3" xs="12">
              <v-text-field
                v-model="search"
                placeholder="Cari"
                outlined
                dense
                hide-details
                prepend-inner-icon="search"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="3" sm="3" xs="12">
              <v-btn
                color="#FBB005"
                class="px-10"
                @click="getListRekapitulasi"
                block
              >
                <span class="subtitle-2 text-capitalize">Cari</span>
              </v-btn>
            </v-col>
          </v-row>
          <v-card-actions v-if="summaries && summaries.length > 0">
            <v-spacer />
            <v-btn
              small
              outlined
              color="#717171"
              class="mx-2 px-5 text-capitalize"
              :loading="loadingExport"
              @click="exportRekapitulasi"
              >Export Excel</v-btn
            >
          </v-card-actions>
          <v-data-table
            :headers="summaryHeaders"
            :items="summaries"
            :expanded.sync="expanded"
            :loading="loading"
            :options.sync="options"
            :server-items-length="pagination.totalItem"
            :footer-props="{
              'items-per-page-options': rowsPerPageItems
            }"
            loading-text="Loading... Please wait"
            item-key="NIP"
            show-expand
            class="elevation-1"
          >
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" class="pa-0">
                <v-data-table
                  id="table-custom"
                  :headers="headers"
                  :items="item.list_jadwal"
                  item-key="no"
                  hide-default-header
                >
                  <template #header>
                    <thead>
                      <tr id="border-btm">
                        <th rowspan="2" class="text-center">Tanggal</th>
                        <th rowspan="2" class="text-center">Jam Kerja</th>
                        <th rowspan="2" class="text-center">Datang</th>
                        <th rowspan="2" class="text-center">Pulang</th>
                        <th rowspan="2" class="text-center">Pulang Cepat</th>
                        <th rowspan="1" colspan="2" class="text-center">
                          Denda Kehadiran
                        </th>
                        <th rowspan="2" class="text-center">Kurang Jam</th>
                        <th rowspan="1" colspan="2" class="text-center">
                          Keberadaan
                        </th>
                        <th rowspan="2" class="text-center">Total Denda</th>
                        <th rowspan="2" class="text-center">Keterangan</th>
                      </tr>
                      <tr>
                        <th class="text-center">Datang</th>
                        <th class="text-center">Pulang</th>
                        <th class="text-center">Durasi</th>
                        <th class="text-center">Denda</th>
                      </tr>
                    </thead>
                  </template>
                  <template v-slot:item="{ item }">
                    <tr v-if="item.schedule_type != ''">
                      <td class="text-center">
                        {{ item.schedule_date | date }}
                      </td>
                      <td class="text-center">
                        {{ item.schedule_in }} - {{ item.schedule_out }}
                      </td>
                      <td class="text-center">{{ item.check_in }}</td>
                      <td class="text-center">{{ item.check_out }}</td>
                      <td class="text-center">{{ item.difference_in }}</td>
                      <td class="text-center">{{ item.result_in }}</td>
                      <td class="text-center">{{ item.result_out }}</td>
                      <td class="text-center">{{ item.difference_out }}</td>
                      <td class="text-center">{{ item.time_diff }}</td>
                      <td class="text-center">{{ item.result_schedule }}</td>
                      <td class="text-center">{{ item.result_schedule }}</td>
                      <td class="text-center">{{ item.keterangan }}</td>
                    </tr>
                    <tr v-else>
                      <td colspan="12" class="text-center">
                        {{ item.attendance_date | date }} - Hari Libur
                      </td>
                    </tr>
                  </template>
                  <template v-slot:no-data>
                    <ContentNotFound message="Laporan kehadiran tidak ada" />
                  </template>
                </v-data-table>
              </td>
            </template>
            <template v-slot:no-data>
              <ContentNotFound message="Laporan kehadiran tidak ada" />
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <CatatanBulkDialog ref="catatanBulk" @on-refresh="getListRekapitulasi" />
    <AttendanceUpdateDialog
      ref="attendanceUpdate"
      @on-refresh="getListRekapitulasi"
    />
  </div>
</template>
<script>
import ReportService from "@/services/resources/report.service";
import DataUtamaService from "@/services/resources/data.utama.service";
const CatatanBulkDialog = () => import("@/components/Dialog/CatatanBulk");
const AttendanceUpdateDialog = () =>
  import("@/components/Dialog/AttendanceUpdate");
const ContentNotFound = () => import("@/components/Content/NotFound");

export default {
  components: {
    CatatanBulkDialog,
    AttendanceUpdateDialog,
    ContentNotFound
  },
  data() {
    return {
      menu: false,
      loading: false,
      loadingUnit: false,
      loadingExport: false,
      search: null,
      dateStart: new Date().toISOString().substr(0, 10),
      menuStart: false,
      dateEnd: new Date().toISOString().substr(0, 10),
      menuEnd: false,
      unit: null,
      unit2: null,
      unit3: null,
      unit4: null,
      units: [],
      units2: [],
      units3: [],
      units4: [],
      expanded: [],
      summaryHeaders: [
        {
          text: "NIP",
          value: "NIP",
          sortable: false,
          align: "center"
        },
        {
          text: "Nama",
          value: "nama",
          sortable: false,
          align: "center"
        },
        { text: "", value: "data-table-expand" }
      ],
      headers: [
        {
          text: "Tanggal",
          value: "attendance_date",
          sortable: false,
          align: "center"
        },
        {
          text: "Masuk",
          value: "check_in",
          sortable: false,
          align: "center"
        },
        {
          text: "Keluar",
          value: "check_out",
          sortable: false,
          align: "center"
        },
        {
          text: "Pulang Cepat",
          value: "check_out_result",
          sortable: false,
          align: "center"
        },
        {
          text: "Kehadiran Pagi",
          value: "check_in_penalty",
          sortable: false,
          align: "center"
        },
        {
          text: "Kehadiran Sore",
          value: "check_out_penalty",
          sortable: false,
          align: "center"
        },
        {
          text: "Kurang Jam",
          value: "attendance_result",
          sortable: false,
          align: "center"
        },
        {
          text: "Keberadaan Durasi",
          value: "present_result",
          sortable: false,
          align: "center"
        },
        {
          text: "Keberadaan Denda",
          value: "present_penalty",
          sortable: false,
          align: "center"
        },
        {
          text: "Total Denda",
          value: "pinalty",
          sortable: false,
          align: "center"
        },
        { text: "", value: "actions", sortable: false, align: "center" }
      ],
      employeeHeaders: [
        {
          text: "NIP",
          value: "NIP",
          sortable: false,
          align: "center"
        },
        {
          text: "Nama",
          value: "name",
          sortable: false,
          align: "center"
        },
        {
          text: "Aksi",
          value: "actions",
          sortable: false,
          align: "center"
        }
      ],
      summaries: [],
      pagination: {
        page: 1,
        itemsPerPage: 10,
        totalItem: 0,
        pageCount: 0
      },
      options: {
        sortBy: ["NIP"],
        sortDesc: [false]
      },
      rowsPerPageItems: [10, 20, 30, 40]
    };
  },
  watch: {
    options: {
      handler() {
        this.fetchListDebounce(this.getListRekapitulasi);
      },
      deep: true
    }
  },
  methods: {
    handleCheck(item) {
      this.$refs.catatanBulk.edit(item.attendance_id);
    },
    handleAdd(item) {
      this.$refs.attendanceUpdate.edit(item);
    },
    changeUnit(type = 2) {
      if (type == 1) {
        this.units2 = this.units.find(
          d => d.unit_utama_id == this.unit
        )?.unit_kerja2;
        this.unit2 = null;
        this.unit3 = null;
        this.unit4 = null;
      } else if (type == 2) {
        this.units3 = this.units2.find(
          d => d.unit_kerja_2_id == this.unit2
        )?.unit_kerja3;
        this.unit3 = null;
        this.unit4 = null;
      } else if (type == 3) {
        this.units4 = this.units3.find(
          d => d.unit_kerja_3_id == this.unit3
        )?.unit_kerja4;
        this.unit4 = null;
      }
    },
    populateUnit() {
      this.units2 = this.units.find(d =>
        d.unit_utama_id.includes(this.unit)
      )?.unit_kerja2;
      this.units3 = this.units2.find(
        d => d.unit_kerja_2_id == this.unit2
      )?.unit_kerja3;
      this.units4 = this.units3.find(
        d => d.unit_kerja_3_id == this.unit3
      )?.unit_kerja4;
    },
    // Service
    async getListUnit() {
      try {
        this.loadingUnit = true;
        await DataUtamaService.getUnitKerjaAll({
          platform: this.isPresensi ? "presensi" : "simpeg"
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.units = data;

              this.populateUnit();
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingUnit = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getListRekapitulasi() {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;
      let orderBy = [];
      sortBy.map((d, i) => {
        let sort = sortDesc[i] ? "desc" : "asc";
        orderBy = [...orderBy, d, sort];
        return d;
      });
      try {
        this.loading = true;
        await ReportService.detailKehadiran({
          export: false,
          date_start: this.dateStart
            ? this.$moment(this.dateStart).format("YYYY-MM-DD")
            : "",
          date_end: this.dateEnd
            ? this.$moment(this.dateEnd).format("YYYY-MM-DD")
            : "",
          filter: {
            search: this.search,
            unit_utama_id: this.unit,
            unit_kerja_2_id: this.unit2,
            unit_kerja_3_id: this.unit3,
            unit_kerja_4_id: this.unit4
          },
          orderBy,
          pageCurrent: page,
          dataPerPage: itemsPerPage
        })
          .then(response => {
            const { status, data } = response.data;
            const { list, pageTotal, countTotal } = data;
            if (status) {
              let summaries = list;
              summaries.map((data, index) => {
                data.no = itemsPerPage * (page - 1) + (index + 1);
                return data;
              });

              this.summaries = summaries;
              this.pagination.totalItem = parseInt(countTotal);
              this.pagination.pageCount = parseInt(pageTotal);
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async exportRekapitulasi() {
      try {
        this.loadingExport = true;
        await ReportService.detailKehadiran({
          export: true,
          date_start: this.dateStart
            ? this.$moment(this.dateStart).format("YYYY-MM-DD")
            : "",
          date_end: this.dateEnd
            ? this.$moment(this.dateEnd).format("YYYY-MM-DD")
            : "",
          filter: {
            search: this.search || "",
            unit_utama_id: this.unit || "",
            unit_kerja_2_id: this.unit2 || "",
            unit_kerja_3_id: this.unit3 || "",
            unit_kerja_4_id: this.unit4 || ""
          }
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              const url = data.file;
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", data.name);
              document.body.appendChild(link);
              link.click();
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingExport = false));
      } catch (err) {
        console.error(err);
      }
    }
  },
  mounted() {
    this.getListUnit();
  }
};
</script>
<style lang="scss">
#table-custom.v-data-table {
  color: #717171 !important;
  .v-data-table__wrapper > table > thead {
    background-color: #fdfdfd;
  }
  .v-data-table__wrapper > table > thead > tr > th {
    font-size: 0.775rem;
    font-weight: 400;
  }
  .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 0.775rem;
  }
}
#card-custom .v-input {
  font-size: 0.775rem;
}
#border-btm > th {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
</style>
